<div class="modal-header">

    <h4 class="float-end font-size-16">Detail article {{position + 1}} #{{refCommande}}</h4>
    <button type="button" class="btn-close" aria-hidden="true" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body back-gray">


    <div class="row">


        <div class="col-lg-12">
            <div class="row">

                <div class="col" *ngIf="coutureImages && coutureImages.length > 0">

                    <div class="product-detai-imgs">
                        <div class="row">
                            <ul ngbNav #nav="ngbNav" class="col-md-2 col-sm-3 col-4" orientation="vertical">
                                <li *ngFor="let myproduct of coutureImages; let i = index" [ngbNavItem]="i" class="p-2">
                                    <a ngbNavLink>
                                        <img src="{{myproduct.img}}" alt="" class="img-fluid mx-auto d-block rounded" />
                                        <p>{{myproduct.nom}}</p>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <img (click)="openImage(myproduct.img, myproduct.nom)" src={{myproduct.img}}
                                            id="expandedImg" alt="" class="img-fluid mx-auto d-block">
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="col-md-7 offset-md-1 col-sm-9 col-8"></div>
                        </div>
                    </div>
                </div>

                <div class="col">

                    <h5 class="mb-3 ms-3">Couture</h5>

                    <ul class="list-unstyled vstack gap-3 mb-0">
                        
                      

                      <li>
                        <div class="d-flex">
                            <i class='bx bx-user font-size-18 text-primary'></i>
                            <div class="ms-3">
                                <h6 class="mb-1 fw-semibold">Statut</h6>
                                <span class="badge badge-pill badge-soft-success font-size-11" [ngClass]=" { 'badge-soft-warning': couture.statut === 'A_TRAITER',
                                    'badge-soft-primary': couture.statut === 'EN_COUTURE','badge-soft-success':couture.statut === 'LIVREE', 
                                    'badge-soft-danger': couture.statut === 'RETOUR','badge-soft-info':couture.statut === 'TERMINEE' }">
                          {{ couture.statut | statut }} 
                            </span>
                            </div>
                        </div>
                    </li>
                        <li>
                            <div class="d-flex">
                                <i class='bx bx-user font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Couture pour</h6>
                                    <span class="text-muted">{{couture.nomProprietaire}}</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="d-flex">
                                <i class='bx bx-user font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Genre</h6>
                                    {{couture.genreCouture | titlecase}}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="d-flex">
                                <i class='bx bx-closet font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Type de vetement</h6>
                                    <span class="text-muted">{{couture.typeVetement}}</span>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="couture.modele">
                            <div class="d-flex">
                                <i class='bx bxs-t-shirt font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Modele</h6>
                                    <span class="text-muted">{{couture.modele}}</span>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="couture.broderie">
                            <div class="d-flex">
                                <i class='bx bx-align-justify font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Broderie</h6>
                                    <span class="text-muted">{{couture.broderie}}</span>
                                </div>
                            </div>
                        </li>

                        <li *ngIf="couture.tissuFourniPar">
                            <div class="d-flex">
                                <i class='bx bxs-rectangle font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Tissu fourni par</h6>
                                    <span class="text-muted">{{couture.tissuFourniPar | titlecase}}</span>
                                </div>
                            </div>
                        </li>

                        <li *ngIf="couture.tissu">
                            <div class="d-flex">
                                <i class='bx bxs-rectangle font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Tissu</h6>
                                    <span class="text-muted">{{couture.tissu}}</span>
                                </div>
                            </div>
                        </li>

                        <li *ngIf="couture.typeTissu">
                            <div class="d-flex">
                                <i class='bx bxs-rectangle font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Type de tissu</h6>
                                    <span class="text-muted">{{couture.typeTissu}}</span>
                                </div>
                            </div>
                        </li>

                        <li *ngIf="couture.couleur">
                            <div class="d-flex">
                                <i class='bx bxs-palette font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Couleur</h6>
                                    <div class="box" [style.background-color]="couture.couleur"></div>
                                </div>
                            </div>
                        </li>

                        <li *ngIf="couture.quantiteTissu">
                            <div class="d-flex">
                                <i class='bx bxs-rectangle font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Nombre de mètres</h6>
                                    <span class="text-muted">{{couture.quantiteTissu}}</span>
                                </div>
                            </div>
                        </li>

                        <li *ngIf="canAccess">
                            <div class="d-flex">
                                <i class='bx bx-money font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Cout</h6>
                                    <span class="text-muted">{{couture.cout | currency : monnaie : 'symbol': '1.0-0':
                                        'fr'}}</span>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div class="d-flex">
                                <i class='bx bx-calendar font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Date de la commande</h6>
                                    <span class="text-muted">{{couture.dateCommande | date}}</span>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div class="d-flex" *ngIf="couture.dateLivraisonPrevue || dateLivraison">
                                <i class='bx bx-calendar font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Date de livraison prévue</h6>
                                    <span *ngIf="couture.dateLivraisonPrevue"
                                        class="text-muted">{{couture.dateLivraisonPrevue | date}}</span>
                                    <span *ngIf="!couture.dateLivraisonPrevue && dateLivraison"
                                        class="text-muted">{{dateLivraison | date}}</span>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div class="d-flex" *ngIf="couture.datePrete">
                                <i class='bx bx-calendar font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Date couture prete</h6>
                                    <span *ngIf="couture.datePrete"
                                        class="text-muted">{{couture.datePrete | date}}</span>
                                    
                                </div>
                            </div>
                        </li>

                        <li>
                            <div class="d-flex" *ngIf="couture.dateLivraisonEffective">
                                <i class='bx bx-calendar font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Date de livraison effective</h6>
                                    <span class="text-muted">{{couture.dateLivraisonEffective | date}}</span>

                                </div>
                            </div>
                        </li>


                        <li>
                            <div class="d-flex" *ngIf="couture.notes">
                                <i class='mdi mdi-google-translate font-size-18 text-primary'></i>
                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">Notes</h6>
                                    <span class="text-muted">{{couture.notes}}</span>
                                </div>
                            </div>
                        </li>

                    </ul>

                </div><!--end col-->

                <div class="col">

                    <h5 class="mb-3 ms-3">Mesures</h5>

                    <p *ngIf="!couture.mesuresList || couture.mesuresList.length == 0" class="ms-3">Aucune mesure
                        renseignée</p>

                    <ul class="list-unstyled vstack gap-1 mb-0">
                        <li *ngFor="let mesure of couture.mesuresList; let i=index">
                            <div class="d-flex">

                                <div class="ms-3">
                                    <h6 class="mb-1 fw-semibold">{{mesure.champ | titlecase}}</h6>
                                    <span class="text-muted">{{mesure.valeur}}</span>
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>

                <div class="col" *ngIf="equipe && equipe.length > 0">

                    <h5 class="ms-3">Equipe</h5>

                    <p *ngIf="!equipe || equipe.length == 0" class="ms-3">Aucun personnel affecté à cette couture</p>


                    <div class="table-responsive" *ngIf="equipe && equipe.length > 0">
                        <table class="table table-nowrap">
                            <tbody>
                                <tr *ngFor="let personel of equipe">
                                    <td>
                                        <h5 class="font-size-12"><a href="" class="text-dark">{{personel.nom}}</a></h5>
                                    </td>
                                    <td>
                                        <div>
                                            <span
                                                class="badge bg-primary bg-soft text-primary font-size-11">{{personel.role
                                                | titlecase}}</span>

                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>


                    </div>

                </div>

                <div class="col" *ngIf="etapesCompleted && etapesCompleted.length > 0">
                    <h5>Etapes</h5>


                    <div *ngFor="let etape of couture.etapes">
                        <p *ngIf="etape.completed" class="form-check form-check-sucess">
                            <input class="form-check-input" type="checkbox" checked disabled /> {{etape.etapeCouture |
                            titlecase}}
                        </p>
                    </div>
                </div>

                <div class="col" *ngIf="couture.activites && couture.activites.length > 0">

                    <h4 class="card-title mb-2">Activités</h4>
                    <ul class="verti-timeline list-unstyled" *ngIf="couture.activites">
                        <li *ngFor="let activity of couture.activites" class="event-list">
                            <div class="event-timeline-dot">
                                <i class="bx bx-right-arrow-circle font-size-18"></i>
                            </div>
                            <div class="media">
                                <div class="mr-3">
                                    <h5 class="font-size-10">{{activity.date | date :'dd MMM, YYYY HH:mm' }} <i
                                            class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                    </h5>
                                </div>
                                <div class="media-body">
                                    <div>
                                        {{activity.description}}
                                        <p>
                                            <small class="text-muted">{{activity.acteur}}</small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
    
                    </ul>
                    <div class="text-center mt-4"></div>
                 </div>


            </div>

            
        </div>
       
            

    </div>
</div>
<div class="modal-footer">


    <button (click)="changeStatut(couture, 'EN_COUTURE')" *ngIf="couture.statut=='A_TRAITER' || couture.statut=='EN_COUTURE' "
        class="btn btn-primary">{{couture.statut=='A_TRAITER' ? 'En couture' : 'Modification en couture'}}</button>
    <button (click)="changeStatut(couture, 'TERMINEE')" *ngIf="couture.statut=='A_TRAITER' || couture.statut=='EN_COUTURE' "
        class="btn btn-success">Terminer</button>
    <button (click)="changeStatut(couture, 'LIVREE')" *ngIf="couture.statut=='TERMINEE'" class="btn btn-success">Livrer</button>

    <button *ngIf="false" class="btn btn-outline-primary">Modifier</button>
    <button *ngIf="false" class="btn btn-outline-danger">Supprimer</button>
    <button class="btn btn-secondary" (click)="activeModal.close('Close click')">Fermer</button>
</div>