import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Lightbox } from 'ngx-lightbox';
import { FormEnCoutureComponent } from '../form-en-couture/form-en-couture.component';
import { Couture } from 'src/app/core/models/couture.model';

@Component({
  selector: 'app-detail-couture',
  templateUrl: './detail-couture.component.html',
  styleUrls: ['./detail-couture.component.scss']
})
export class DetailCoutureComponent implements OnInit {

  couture;
  refCommande;
  idCommande;
  modele;
  monnaie;
  modeles;

  coutureImages = [];
  equipe = [];
  role;
  canAccess;

  dateLivraison;

  position;

  etapesCompleted;

  @Output()
  coutureChange : EventEmitter<Couture> = new EventEmitter<Couture>();


  constructor(public activeModal: NgbActiveModal, private lightbox: Lightbox, private modalService: NgbModal) {
    
  }

  ngOnInit(): void {

    this.role = sessionStorage.getItem('role');
    this.canAccess = this.role == 'Admin Couturier' || this.role == 'Boutique';

    if(this.couture && this.couture.photoTissu) {
      const tissu = {
        img: this.couture.photoTissu,
        nom: 'Tissu'
      }
      this.coutureImages.push(tissu)
    }

    if(this.couture.dateLivraisonPrevue){
      this.dateLivraison = this.couture.dateLivraisonPrevue;
    }

    this.getModele();

    this.getEquipes();

    this.getEtapesCompleted();

  }

  getEtapesCompleted(){
    this.etapesCompleted = this.couture.etapes.filter(e=>e.completed);
  }

  getModele(){

    if(this.coutureImages.length == 2){
      this.coutureImages.splice(1, 1);
    }

    if(this.couture && this.couture.modele){
      const foundModeles = this.modeles.filter(m=>m.nom.toLowerCase().trim()==this.couture.modele.toLowerCase().trim())
      if(foundModeles.length > 0){
      
        const foundModele = foundModeles[0]
      
        if(foundModele!=null) {
          this.modele = foundModele.photo
          if(this.modele){
            const modeleImg = {
              img: this.modele,
              nom: 'Modele'
            }
            this.coutureImages.push(modeleImg);
          }
        }
      }
    }

  }

  getEquipes(){
    this.equipe = [];
    if(this.couture.coupeurs && this.couture.coupeurs.length > 0){
      this.equipe = this.equipe.concat(this.couture.coupeurs)
    }

    if(this.couture.brodeurs && this.couture.brodeurs.length > 0){
      this.equipe = this.equipe.concat(this.couture.brodeurs)
    }

    if(this.couture.piqueurs && this.couture.piqueurs.length > 0){
      this.equipe = this.equipe.concat(this.couture.piqueurs)
    }
  }



  changeStatut(couture, statut){
    const modalRef = this.modalService.open(FormEnCoutureComponent, { size: 'lg', centered: true });
		modalRef.componentInstance.couture = couture;
    modalRef.componentInstance.refCommande = this.refCommande;

    modalRef.componentInstance.dateLivraison = this.dateLivraison;
    modalRef.componentInstance.position = this.position;
    modalRef.componentInstance.idCommande = this.idCommande;
    modalRef.componentInstance.statut = statut;
    modalRef.componentInstance.modeles = this.modeles;
    modalRef.componentInstance.coutureChange.subscribe(c => {
      this.couture = c;
      this.getEquipes();
      this.coutureChange.emit(c);
      this.getModele();
    });

  }


 

  openImage(url: string, type: string): void {
    // open lightbox

    const images = [];

    const src = url;
      const caption = type;
      const thumb = url;
      const album = {
        src,
        caption,
        thumb
      };

      images.push(album);
    
    this.lightbox.open(images, 0, {
      showZoom: true
    });
  }

}
