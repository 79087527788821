import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbonnementService } from 'src/app/core/services/entities/abonnement.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {

  tenant = sessionStorage.getItem('tenant');

  @Input() monnaie = 'XOF';
  @Input() montant = 55;

  @Output()
  paymentResult: EventEmitter<string> = new EventEmitter<string>();

  loading$ = this.loader.loading$;



  constructor(private service: AbonnementService, private loader: LoaderService) { }

  ngOnInit(): void {
    this.initializePayPalButtons();
  }

  initializePayPalButtons(): void {
    // Vérifiez si le SDK PayPal est chargé
    if ((window as any).paypal) {
      this.loader.show();
      (window as any).paypal.Buttons({
        createOrder: (data: any, actions: any) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: '' + this.formatNumberToDecimal(this.montant), // Montant de la transaction
                //currency_code: this.monnaie
              }
            }]
          });
        },
        onApprove: (data: any, actions: any) => {
          return actions.order.capture().then((details: any) => {

            //this.paymentResult.emit("success");

            //alert(`Transaction completed by ${details.payer.name.given_name}`);
            // Ajouter votre logique après paiement ici



            let body = {
              mode: 'PayPal',
              montant: '35000',
              status: '200',
              tenant: this.tenant,

            }

            this.service.touchpay(body).subscribe(result => {

              Swal.fire('Paiement réussi!', 'Vous avez renouvellé votre abonnement!', 'success');

              this.paymentResult.emit("success");

              this.loader.hide();

            }, error => {

              Swal.fire('Paiement en erreur!', 'Le paiement de votre abonnement a été annulé!', 'error');
              this.paymentResult.emit("failed");
              this.loader.hide();

            });

          });
        },
        onError: (err: any) => {
          this.paymentResult.emit("failed");
          Swal.fire('Paiement en erreur!', 'Le paiement de votre abonnement a été annulé: ' + err, 'error');
          console.error('PayPal Error:', err);
          this.loader.hide();
        }
      }).render('#paypal-button-container');
    } else {
      console.error('PayPal SDK not loaded.');
    }
  }

  formatNumberToDecimal(value: number): string {
    return value.toFixed(1); // 1 chiffre après la virgule
  }

}
