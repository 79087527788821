import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

import imageCompression from 'browser-image-compression';

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    imageUrl: string | null = null;
    progress: number = 0;
    tenant;

    constructor(private storage: AngularFireStorage) {
        this.tenant = sessionStorage.getItem('tenant');
    }

    deleteFileOnStorage(fileUrl){
        this.storage.refFromURL(fileUrl).delete();
    }


    async uploadFile(file: File, path: string): Promise<string> {
        return this.uploadFileWithName(file, path, null);
    }

    async uploadFileWithName(file: File, path: string, filename: string): Promise<string> {

        if(filename == null){
            filename = ''
        }else{
            filename+='-'
        }

        if (!file) {
            throw new Error('No file provided');
        }

        try {
            // Compress the file
            const options = {
                maxSizeMB: 1, // Max size in MB
                maxWidthOrHeight: 1024, // Max width or height
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);

            // Define the Firebase storage path
            //const filePath = `${path}/${new Date().getTime()}_${compressedFile.name}`;
            const filePath = `${path}/${this.tenant}/${filename}${new Date().getTime()}`;
            const fileRef = this.storage.ref(filePath);

            // Upload the file
            const uploadTask = await this.storage.upload(filePath, compressedFile);

            // Get the download URL
            const downloadURL = await fileRef.getDownloadURL().toPromise();
            return downloadURL;
        } catch (error) {
            console.error('Error uploading file: ', error);
            throw error;
        }
    }

}


export enum ImageFolder {
    CLIENT = 'PhotosClient',
    TISSU = 'TissuImages',
    MES_TISSUS = 'MesTissuImages',
    LOGO = 'Logo',
    LOGO_TRANSPARANT = 'LogoTransparent',
    LOGO_SMALL = 'LogoSmall',
    MODELE = 'ModelesImages',
    PERSONNEL = 'PersonnelImages',
    TYPE_COUTURE = 'TypesCouturesImages'
}