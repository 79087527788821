<div class="card" >
    <div class="card-body">
        <h4 class="card-title mb-4" >Moyens de paiements </h4>

        <div>
            <apx-chart *ngIf="statsMoyenPaiement" class="apex-charts" dir="ltr" [series]="salesAnalyticsDonutChart.series"
                [chart]="salesAnalyticsDonutChart.chart" [colors]="salesAnalyticsDonutChart.colors"
                [plotOptions]="salesAnalyticsDonutChart.plotOptions"
                [legend]="salesAnalyticsDonutChart.legend" [labels]="salesAnalyticsDonutChart.labels">
            </apx-chart>
        </div>

        <div class="text-center text-muted">
            <div class="row">
                <div class="col" *ngFor="let moyen of statsMoyenPaiement.moyensPaiement; let i=index">
                    <div class="mt-4">
                        <p class="mb-2 text-truncate"><i class="mdi mdi-circle me-1" [style.color]="statsMoyenPaiement.colors[i] "></i>
                           {{moyen}}</p>
                        <h5>{{statsMoyenPaiement.montants[i]  | currency : monnaie : 'symbol': '1.0-0': 'fr' }}</h5>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
