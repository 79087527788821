import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { StatComponent } from './stat/stat.component';
import { TransactionComponent } from './transaction/transaction.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { StatsCommandeStatutComponent } from './stats-commande-statut/stats-commande-statut.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { StatsPaiementsRecusComponent } from './stats-paiements-recus/stats-paiements-recus.component';
import { StatsMoyenPaiementComponent } from './stats-moyen-paiement/stats-moyen-paiement.component';
import { PaypalComponent } from './paypal/paypal.component';
import { LoaderService } from 'src/app/core/services/loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptorService } from 'src/app/core/services/interceptors/loader-interceptor.service';

@NgModule({
  declarations: [StatComponent, TransactionComponent, StatsCommandeStatutComponent, StatsPaiementsRecusComponent, StatsMoyenPaiementComponent, PaypalComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    RouterModule,
    FormsModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgApexchartsModule,
    //LoaderService,
  ],
  providers: [
      LoaderService,
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    ],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ],
  exports: [StatComponent, TransactionComponent, StatsCommandeStatutComponent, StatsPaiementsRecusComponent, StatsMoyenPaiementComponent, PaypalComponent]
})
export class WidgetModule { }
