import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'src/app/core/models/chart.model';
import { ParametrageService } from 'src/app/core/services/entities/parametrage.service';

@Component({
  selector: 'app-stats-moyen-paiement',
  templateUrl: './stats-moyen-paiement.component.html',
  styleUrls: ['./stats-moyen-paiement.component.scss']
})
export class StatsMoyenPaiementComponent implements OnInit {

  @Input() statsMoyenPaiement: any;

  @Input() text: string;
  salesAnalyticsDonutChart: any;

  monnaie = 'XOF';

  constructor(private parametrageService: ParametrageService) { }

  ngOnInit(): void {


    this.parametrageService.get().subscribe(result =>{
      this.monnaie = result.currency;
    });

    let data = this.statsMoyenPaiement;

    console.log(data);



    this.salesAnalyticsDonutChart = {
      series: this.statsMoyenPaiement.montants,
      chart: {
          type: 'donut',
          height: 240,
      },
      labels: this.statsMoyenPaiement.moyensPaiement,
      colors: this.statsMoyenPaiement.colors,
      legend: {
          show: false,
      },
      plotOptions: {
          pie: {
              donut: {
                  size: '70%',
              }
          }
      }
    }
  }
}
